import {
	Button,
	Card,
	CardContent,
	CardHeader,
	CircularProgress,
	FormControl,
	FormControlLabel,
	Grid,
	IconButton,
	InputAdornment,
	InputLabel,
	MenuItem,
	Paper,
	Select,
	Switch,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
	Tooltip,
	Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { BackButton } from "../../components/BackButton";
import { ClientLink } from "../../components/ClientLink";
import { PageTitle } from "../../components/PageTitle";
import { Price } from "../../components/Price";
import { PriceTextField } from "../../components/PriceTextField";
import { useAlert } from "../../hooks/useAlert";
import { routes } from "../../routes";
import { CustomerBalanceReport, ReportService } from "../../services/report/ReportService";
import { NumberTextField } from "../../components/NumberTextField";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import { TagBubble } from "../../components/TagBubble";
import { GridGrow } from "../../components/GridGrow";
import { TagService } from "../../services/customer/TagService";
import { Tag } from "../../entities/customer/Tag";

function printReport(report: CustomerBalanceReport) {
	const printWindow = window.open("", "PRINT", "height=400,width=600");
	if (!printWindow) return;

	const tableHeaders = `
	<tr>
		<th>Customer</th>
		<th>Address</th>
		<th>Balance</th>
		<th>0-30</th>
		<th>31-60</th>
		<th>61-90</th>
		<th>91-120</th>
		<th>120+</th>
	</tr>
	`;

	const tableRows = report.items
		.map(
			(c) => `
    <tr>
      <td>${c.customerName}${c.tags.length > 0 ? `<br>${c.tags.map((t) => `<span style="color: #5f5f5f">${t.text}</span>`).join("<br>")}` : ""}</td>
      <td>${c.customerAddress}</td>
      <td>${c.balance.toFixed(2)}</td>
      <td>${c.overdue30.toFixed(2)}</td>
	  <td>${c.overdue60.toFixed(2)}</td>
	  <td>${c.overdue90.toFixed(2)}</td>
	  <td>${c.overdue120.toFixed(2)}</td>
	  <td>${c.overdueOver120.toFixed(2)}</td>
    </tr>
  `
		)
		.join("");

	printWindow.document.write(`
    <html>
      <head>
        <title>Customer Balance Report</title>
        <style>
			@page {
				size: auto;
				margin: 5mm;
			}
			body {
				margin: 5mm;
			}
			table {
				width: 100%;
				border-collapse: collapse;
			}
			table, th, td {
				border: 1px solid black;
			}
			th, td {
				padding: 5px;
			}
			th {
				text-align: left;
			}
			tr {
				page-break-inside: avoid;
			}
        </style>
      </head>
      <body>
        <table border="1">
          <thead>
            ${tableHeaders}
          </thead>
          <tbody>
            ${tableRows}
          </tbody>
        </table>
      </body>
    </html>
  `);
	printWindow.document.close();
	printWindow.focus();
	printWindow.print();
	printWindow.close();
}

function exportToCsv(report: CustomerBalanceReport) {
	const headers = ["Customer", "Code", "Tags", "Address", "Balance", "0-30", "31-60", "61-90", "91-120", "120+"];

	const rows = report.items.map((c) => [
		`"${c.customerName.replace(/"/g, '""')}"`,
		`"${c.customerCode}"`,
		`"${c.tags.map((tag) => tag.text).join("; ")}"`,
		`"${c.customerAddress.replace(/"/g, '""')}"`,
		c.balance.toFixed(2),
		c.overdue30.toFixed(2),
		c.overdue60.toFixed(2),
		c.overdue90.toFixed(2),
		c.overdue120.toFixed(2),
		c.overdueOver120.toFixed(2),
	]);

	let csvContent = headers.join(",") + "\n";

	rows.forEach((rowArray) => {
		const row = rowArray.join(",");
		csvContent += row + "\n";
	});

	const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
	const url = URL.createObjectURL(blob);
	const link = document.createElement("a");
	link.setAttribute("href", url);
	link.setAttribute("download", "CustomerBalanceReport.csv");
	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
}

export function CustomerBalanceReportPage() {
	const alert = useAlert();

	const headerRef = React.useRef<HTMLDivElement>(null);
	const bottomMargin = 10;
	const headerHeight = headerRef.current ? headerRef.current.offsetHeight + headerRef.current.offsetTop : 250;

	const [disabled, setDisabled] = useState(false);
	const [includeBalanceGreaterThan, setIncludeBalanceGreaterThan] = useState<number | null>(null);
	const [includeBalanceLessThan, setIncludeBalanceLessThan] = useState<number | null>(null);
	const [includeInactive, setIncludeInactive] = useState(false);
	const [cityInput, setCityInput] = useState<string>("");
	const [minBalanceAge, setMinBalanceAge] = useState<number | null>(null);
	const [report, setReport] = useState<CustomerBalanceReport>();
	const [tags, setTags] = useState<Tag[]>([]);
	const [selectedTags, setSelectedTags] = useState<Tag[]>([]);
	const [loadingTags, setLoadingTags] = useState<boolean>(true);
	const [paymentMethod, setPaymentMethod] = useState<"Has Payment Method" | "No Payment Method" | null>(null);

	const city = cityInput.trim() === "" ? null : cityInput.trim();

	const handleTagSelect = (event: React.ChangeEvent<{ value: unknown }>) => {
		const tagId = event.target.value as number;
		const tag = tags.find((t) => t.id === tagId);
		if (tag && !selectedTags.some((t) => t.id === tag.id)) {
			setSelectedTags([...selectedTags, tag]);
		}
	};

	const handleTagDelete = (tag: Tag) => {
		setSelectedTags(selectedTags.filter((t) => t.id !== tag.id));
	};

	const handlePaymentMethodChange = (event: React.ChangeEvent<{ value: unknown }>) => {
		const value = event.target.value as "Has Payment Method" | "No Payment Method" | "";
		setPaymentMethod(value === "" ? null : value);
	};

	const generateReport = async () => {
		setDisabled(true);
		const result = await ReportService.customerBalanceReport({
			includeBalanceGreaterThan,
			includeBalanceLessThan,
			includeInactive,
			city,
			minBalanceAge,
			tagIds: selectedTags.map((t) => t.id).join(","),
			paymentMethod
		});
		setDisabled(false);

		if (result.success) {
			setReport(result.data);
		} else {
			alert.serverError(result);
		}
	};

	useEffect(() => {
		async function loadTags() {
			setLoadingTags(true);
			try {
				const result = await TagService.getAll();
				if (result.success) {
					setTags(result.data.filter((t) => t.type === "Customer"));
				} else {
					alert.serverError(result);
				}
			} finally {
				setLoadingTags(false);
			}
		}
		loadTags();
	}, [alert]);

	return (
		<>
			<PageTitle title="Customer Balance Report" />
			<div ref={headerRef}>
				<Grid container justify="center" spacing={1}>
					<Grid item md={8} sm={10} xs={12}>
						<Card>
							<Grid container alignItems="center">
								<Grid item>
									<BackButton />
								</Grid>
								<Grid item>
									<CardHeader title="Customer Balance Report" subheader="Report of outstanding customers balances." />
								</Grid>
							</Grid>
							<CardContent>
								<Grid container spacing={2}>
									<Grid item md={3} sm={4} xs={12}>
										<PriceTextField
											label="Min Balance"
											fullWidth
											onPriceChanged={(p) => setIncludeBalanceGreaterThan(p)}
											value={includeBalanceGreaterThan}
											variant="outlined"
											disabled={disabled}
											InputProps={{
												endAdornment: (
													<InputAdornment position="end">
														<Tooltip title="Only include customers with a balance more than this amount.">
															<IconButton>
																<HelpOutlineIcon />
															</IconButton>
														</Tooltip>
													</InputAdornment>
												),
											}}
										/>
									</Grid>
									<Grid item md={3} sm={4} xs={12}>
										<PriceTextField
											label="Max Balance"
											fullWidth
											onPriceChanged={(p) => setIncludeBalanceLessThan(p)}
											value={includeBalanceLessThan}
											variant="outlined"
											disabled={disabled}
											InputProps={{
												endAdornment: (
													<InputAdornment position="end">
														<Tooltip title="Only include customers with a balance less than this amount.">
															<IconButton>
																<HelpOutlineIcon />
															</IconButton>
														</Tooltip>
													</InputAdornment>
												),
											}}
										/>
									</Grid>
									<Grid item md={3} sm={4} xs={12}>
										<NumberTextField
											label="Min Balance Age (Days)"
											fullWidth
											variant="outlined"
											disabled={disabled}
											value={minBalanceAge}
											onNumberChange={(n) => setMinBalanceAge(n)}
											InputProps={{
												endAdornment: (
													<InputAdornment position="end">
														<Tooltip title="Only include customers with a balance that is at least this many days old.">
															<IconButton>
																<HelpOutlineIcon />
															</IconButton>
														</Tooltip>
													</InputAdornment>
												),
											}}
										/>
									</Grid>
									<Grid item md={3} sm={4} xs={12}>
										<TextField
											label="City"
											fullWidth
											variant="outlined"
											disabled={disabled}
											value={cityInput}
											onChange={(e) => setCityInput(e.target.value)}
										/>
									</Grid>
									<Grid item md={3} sm={4} xs={12}>
										<FormControl variant="outlined" fullWidth disabled={disabled || loadingTags}>
											<InputLabel id="tag-select-label">Select Tags</InputLabel>
											<Select
												labelId="tag-select-label"
												id="tag-select"
												value=""
												onChange={handleTagSelect}
												label="Filter by Tags"
												endAdornment={
													loadingTags ? (
														<InputAdornment position="end">
															<CircularProgress size={20} />
														</InputAdornment>
													) : undefined
												}
											>
												{tags.map((tag) => (
													<MenuItem key={tag.id} value={tag.id} disabled={selectedTags.some((t) => t.id === tag.id)}>
														{tag.text}
													</MenuItem>
												))}
												{tags.length === 0 && (
													<MenuItem disabled value="">
														{loadingTags ? "Loading tags..." : "No tags available"}
													</MenuItem>
												)}
											</Select>
										</FormControl>
									</Grid>
									<Grid item md={3} sm={4} xs={12}>
										<FormControl variant="outlined" fullWidth disabled={disabled}>
											<InputLabel id="payment-method-label">Payment Method</InputLabel>
											<Select
												labelId="payment-method-label"
												id="payment-method-select"
												value={paymentMethod ?? ""}
												onChange={handlePaymentMethodChange}
												label="Payment Method"
											>
												<MenuItem value="">
													<em>Include All</em>
												</MenuItem>
												<MenuItem value="Has Payment Method">Has Payment Method</MenuItem>
												<MenuItem value="No Payment Method">No Payment Method</MenuItem>
											</Select>
										</FormControl>
									</Grid>
									<Grid item md={4} sm={6} xs={12}>
										<FormControlLabel
											label="Include Inactive Customers"
											control={
												<Switch checked={includeInactive} color="primary" onChange={() => setIncludeInactive(!includeInactive)} disabled={disabled} />
											}
										/>
									</Grid>

									<Grid item xs={12}>
										{selectedTags.length > 0 && (
											<>
												<Typography variant="subtitle2" gutterBottom>
													Selected Tags:
												</Typography>
												<Grid container spacing={1} style={{ marginBottom: 16 }}>
													{selectedTags.map((tag) => (
														<Grid item key={tag.id}>
															<TagBubble tag={tag} onDelete={handleTagDelete} />
														</Grid>
													))}
												</Grid>
											</>
										)}
									</Grid>

									<Grid item xs={12}>
										<Grid container spacing={2}>
											<Grid item>
												<Button variant="contained" color="primary" disabled={disabled} onClick={generateReport}>
													Generate
												</Button>
											</Grid>
											<GridGrow />
											<Grid item>
												<Button variant="outlined" disabled={!report} onClick={() => report && exportToCsv(report)}>
													Export
												</Button>
											</Grid>
											<Grid item>
												<Button variant="outlined" disabled={!report} onClick={() => report && printReport(report)}>
													Print
												</Button>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
							</CardContent>
						</Card>
					</Grid>
				</Grid>
			</div>

			{report && (
				<Grid container justify="center" spacing={2}>
					<Grid item sm={10} xs={12}>
						<Paper>
							<TableContainer style={{ maxHeight: `calc(100vh - ${headerHeight + bottomMargin}px` }}>
								<Table stickyHeader>
									<TableHead>
										<TableRow>
											<TableCell>Customer</TableCell>
											<TableCell>Address</TableCell>
											<TableCell>Balance</TableCell>
											<TableCell style={{ minWidth: 110 }}>Overdue 0-30</TableCell>
											<TableCell style={{ minWidth: 110 }}>Overdue 31-60</TableCell>
											<TableCell style={{ minWidth: 110 }}>Overdue 61-90</TableCell>
											<TableCell style={{ minWidth: 110 }}>Overdue 91-120</TableCell>
											<TableCell style={{ minWidth: 110 }}>Overdue 120+</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										<TableRow>
											<TableCell>{report.items.length} Customers</TableCell>
											<TableCell></TableCell>
											<TableCell>
												<Price value={report.totalBalance} />
											</TableCell>
											<TableCell>
												<Price value={report.totalOverdue30} />
											</TableCell>
											<TableCell>
												<Price value={report.totalOverdue60} />
											</TableCell>
											<TableCell>
												<Price value={report.totalOverdue90} />
											</TableCell>
											<TableCell>
												<Price value={report.totalOverdue120} />
											</TableCell>
											<TableCell>
												<Price value={report.totalOverdueOver120} />
											</TableCell>
										</TableRow>
										{report.items.map((c) => (
											<TableRow key={c.customerId}>
												<TableCell>
													<ClientLink newTab color="textPrimary" to={routes.app.resolve.customerDetailPage(c.customerId)}>
														[{c.customerCode}] {c.customerName}
													</ClientLink>
													{c.tags.length > 0 && (
														<>
															<br />
															{c.tags.map((t) => (
																<TagBubble key={t.id} tag={t} />
															))}
														</>
													)}
												</TableCell>
												<TableCell>{c.customerAddress}</TableCell>
												<TableCell>
													<Price value={c.balance} />
												</TableCell>
												<TableCell>
													<Price value={c.overdue30} />
												</TableCell>
												<TableCell>
													<Price value={c.overdue60} />
												</TableCell>
												<TableCell>
													<Price value={c.overdue90} />
												</TableCell>
												<TableCell>
													<Price value={c.overdue120} />
												</TableCell>
												<TableCell>
													<Price value={c.overdueOver120} />
												</TableCell>
											</TableRow>
										))}
									</TableBody>
								</Table>
							</TableContainer>
						</Paper>
					</Grid>
				</Grid>
			)}
		</>
	);
}
