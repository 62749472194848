import { Button, Card, CardContent, CardHeader, Grid, MenuItem, TextField } from "@material-ui/core";
import React, { useState } from "react";
import { BackButton } from "../../components/BackButton";
import { PageTitle } from "../../components/PageTitle";
import { PriceTextField } from "../../components/PriceTextField";
import { useAlert } from "../../hooks/useAlert";
import { ReportService } from "../../services/report/ReportService";
import { NumberTextField } from "../../components/NumberTextField";

export function StatementReportPage() {
	const alert = useAlert();
	const today = new Date();
	const yearOptions = [today.getFullYear(), today.getFullYear() - 1, today.getFullYear() - 2, today.getFullYear() - 3];
	const monthOptions = [
		{ value: 1, label: "January" },
		{ value: 2, label: "February" },
		{ value: 3, label: "March" },
		{ value: 4, label: "April" },
		{ value: 5, label: "May" },
		{ value: 6, label: "June" },
		{ value: 7, label: "July" },
		{ value: 8, label: "August" },
		{ value: 9, label: "September" },
		{ value: 10, label: "October" },
		{ value: 11, label: "November" },
		{ value: 12, label: "December" },
	];

	const [disabled, setDisabled] = useState(false);
	const [minimumBalance, setMinimumBalance] = useState<number | null>(0.01);
	const [year, setYear] = useState(today.getFullYear());
	const [month, setMonth] = useState(today.getMonth() + 1);
	const [daysSinceLastStatement, setDaysSinceLastStatement] = useState<number | null>(30);
	const [markAsSent, setMarkAsSent] = useState(true);
	const [includeInactive, setIncludeInactive] = useState(false);
	const [emailStatements, setEmailStatements] = useState(true);

	const downloadReport = async () => {
		setDisabled(true);
		const result = await ReportService.downloadStatementReport({ year, month, minimumBalance, markAsSent, daysSinceLastStatement, includeInactive, emailStatements });
		setDisabled(false);

		if (result.error) {
			alert.serverError(result);
		}
	};

	return (
		<Grid container justify="center">
			<PageTitle title="Statement Report" />
			<Grid item lg={7} md={9} sm={10} xs={12}>
				<Card>
					<Grid container alignItems="center">
						<Grid item>
							<BackButton />
						</Grid>
						<Grid item>
							<CardHeader title="Statement" subheader="Generate statements for many customers at once." />
						</Grid>
					</Grid>
					<CardContent>
						<Grid container spacing={2}>
							<Grid item sm={4} xs={12}>
								<PriceTextField
									label="Minimum Balance"
									fullWidth
									onPriceChanged={(p) => setMinimumBalance(p)}
									value={minimumBalance}
									variant="outlined"
									disabled={disabled}
									placeholder="$0.00"
								/>
							</Grid>
							<Grid item sm={4} xs={12}>
								<TextField
									select
									fullWidth
									label="Month"
									variant="outlined"
									value={month}
									disabled={disabled}
									onChange={(e) => setMonth(parseInt(e.target.value))}
								>
									{monthOptions.map((m) => (
										<MenuItem key={m.value} value={m.value}>
											{m.label}
										</MenuItem>
									))}
								</TextField>
							</Grid>
							<Grid item sm={4} xs={12}>
								<TextField
									select
									fullWidth
									label="Year"
									variant="outlined"
									value={year}
									disabled={disabled}
									onChange={(e) => setYear(parseInt(e.target.value))}
								>
									{yearOptions.map((y) => (
										<MenuItem key={y} value={y}>
											{y}
										</MenuItem>
									))}
								</TextField>
							</Grid>
							<Grid item sm={4} xs={12}>
								<NumberTextField
									label="Days Since Last Statement"
									fullWidth
									variant="outlined"
									value={daysSinceLastStatement}
									disabled={disabled}
									onNumberChange={setDaysSinceLastStatement}
									helperText="Only customers who have not received a statement in this many days will be included"
								/>
							</Grid>
							<Grid item sm={4} xs={12}>
								<TextField
									select
									fullWidth
									label="Mark as Sent"
									variant="outlined"
									value={markAsSent}
									disabled={disabled}
									onChange={(e) => setMarkAsSent(e.target.value === "true")}
									helperText="Mark the statement as sent in the customer's history"
								>
									<MenuItem value={"true"}>Yes</MenuItem>
									<MenuItem value={"false"}>No</MenuItem>
								</TextField>
							</Grid>
							<Grid item sm={4} xs={12}>
								<TextField
									select
									fullWidth
									label="Include Inactive"
									variant="outlined"
									value={includeInactive}
									disabled={disabled}
									onChange={(e) => setIncludeInactive(e.target.value === "true")}
									helperText="Include customers that are marked as inactive"
								>
									<MenuItem value={"true"}>Yes</MenuItem>
									<MenuItem value={"false"}>No</MenuItem>
								</TextField>
							</Grid>

							<Grid item sm={4} xs={12}>
								<TextField
									select
									fullWidth
									label="Email Statements"
									variant="outlined"
									value={emailStatements}
									disabled={disabled}
									onChange={(e) => setEmailStatements(e.target.value === "true")}
									helperText="Email statements to customers with emails on file. This will exclude them from the PDF download."
								>
									<MenuItem value={"true"}>Yes</MenuItem>
									<MenuItem value={"false"}>No</MenuItem>
								</TextField>
							</Grid>
							<Grid item xs={12}>
								<Button variant="contained" color="primary" disabled={disabled} onClick={downloadReport}>
									Run Statements
								</Button>
							</Grid>
						</Grid>
					</CardContent>
				</Card>
			</Grid>
		</Grid>
	);
}
